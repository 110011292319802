var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "tab-bar" },
      _vm._l(_vm.tabbar, function (tab, key) {
        return _c(
          "div",
          {
            key: key,
            class: { active: key == _vm.checkedTab },
            on: {
              click: function ($event) {
                return _vm.changeTabBar(key, "checkedTab")
              },
            },
          },
          [_vm._v(" " + _vm._s(tab.name || tab) + " ")]
        )
      }),
      0
    ),
    _vm.tabbar[_vm.checkedTab].children &&
    _vm.tabbar[_vm.checkedTab].children.length > 0
      ? _c(
          "div",
          { staticClass: "tab-bar" },
          _vm._l(_vm.tabbar[_vm.checkedTab].children, function (childTab) {
            return _c(
              "div",
              {
                key: childTab.id,
                class: { active: _vm.checkedChildTab === childTab.id },
                on: {
                  click: function ($event) {
                    return _vm.changeTabBar(childTab.id, "checkedChildTab")
                  },
                },
              },
              [_vm._v(" " + _vm._s(childTab.name) + " ")]
            )
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }